import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCUUGRPUa4jIiP1IKBqk9xU7QXJ2sNlNzU",
  authDomain: "freelance-db-91cf3.firebaseapp.com",
  projectId: "freelance-db-91cf3",
  storageBucket: "freelance-db-91cf3.appspot.com",
  messagingSenderId: "976854095096",
  appId: "1:976854095096:web:7c6546d883b2c68b0ed70d",
  measurementId: "G-3DRZK4VYKR",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

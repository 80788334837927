import {
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import { FieldArea } from "../DynamicForm.style";
import Form from "../../../../../store/form-store";
import { FC } from "react";
import { observer } from "mobx-react-lite";

type Props = {
  props: Form;
};

const CreateForm: FC<Props> = observer(({ props }) => {
  const {
    form: { email, password, confirmPassword },
    onChangeFormHandler,
    onCreateNewUserWithEmailAndPassword,
  } = props;

  const onSignUpHandler = () => {
    onCreateNewUserWithEmailAndPassword(email, password);
  };

  return (
    <FieldArea>
      <Typography fontWeight={600} fontSize={32} align="center">
        Create your account
      </Typography>

      <CustomTextField
        label={"Email"}
        placeholder="Enter your email"
        value={email}
        onChange={(e) => onChangeFormHandler("email", e.target.value)}
      />
      <CustomTextField
        type="password"
        label={"Password"}
        placeholder="Enter your email"
        value={password}
        onChange={(e) => onChangeFormHandler("password", e.target.value)}
      />

      <CustomTextField
        type="password"
        label={"Confirm password"}
        placeholder="Confirm your password"
        value={confirmPassword}
        onChange={(e) => onChangeFormHandler("confirmPassword", e.target.value)}
      />
      <FormControl sx={{ mx: 1 }}>
        <FormControlLabel
          control={<Checkbox disableRipple color={"primary"} />}
          label={"I accept Edit Categories and Edit Categories"}
        />
      </FormControl>
      <Button fullWidth variant="contained" onClick={onSignUpHandler}>
        Sign up
      </Button>
    </FieldArea>
  );
});

export default CreateForm;

const Google = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3098 20.1044C33.3098 19.1776 33.2363 18.2458 33.0796 17.334H20.5098V22.5842H27.7079C27.4092 24.2775 26.1818 25.9821 24.7764 26.934L25.0441 30.134L29.0431 30.134C31.5649 27.7593 33.3098 24.2525 33.3098 20.1044Z"
        fill="#4285F4"
      />
      <path
        d="M20.185 33.3337C23.7892 33.3337 26.8286 32.1652 29.0432 30.1482L24.7765 26.9337C23.5784 27.7385 21.992 28.1118 20.1899 28.1118C16.7036 28.1118 13.5705 25.7894 12.5098 22.667H8.24316V26.0658C10.5117 30.5215 15.1323 33.3337 20.185 33.3337Z"
        fill="#34A853"
      />
      <path
        d="M12.5096 22.6672C11.8983 21.0445 12.1649 18.9565 12.7763 17.3339L13.0429 14.1339L8.2429 13.8672C6.17303 17.5591 6.12545 22.1753 8.19533 25.8672L12.5096 22.6672Z"
        fill="#FBBC04"
      />
      <path
        d="M20.4422 11.8869C22.3885 11.8578 24.2695 12.5653 25.679 13.864L29.5765 10.0988C27.1086 7.85997 23.8331 6.62911 20.4422 6.66788C15.2807 6.66788 10.5606 9.40878 8.24316 13.867L12.7777 17.3337C13.8561 14.2081 16.8808 11.8869 20.4422 11.8869Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default Google;

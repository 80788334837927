import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

import { auth } from "../firebase";

export const createUserWithEmailHandler = (email: string, password: string) => {
  createUserWithEmailAndPassword(auth, email, password).catch((e) =>
    console.log(e)
  );
};

export const signUpWithGoogleHandler = () => {
  signInWithPopup(auth, new GoogleAuthProvider());
};

import { DynamicFormRoot } from "./DynamicForm.style";
import { StateConfig } from "../UserForm/FormComponent.style";
import { FC } from "react";
import { CSSTransition } from "react-transition-group";
import LoginForm from "./Forms/LoginForm";
import ResetForm from "./Forms/ResetForm";
import CreateForm from "./Forms/CreateForm";

import Form from "../../../../store/form-store";

type DynamicFormProps = {
  state: StateConfig;
  slideToTheCenter: () => void;
};

const loginForm = new Form();
const createForm = new Form();
const resetForm = new Form();

const DynamicForm: FC<DynamicFormProps> = ({ state, slideToTheCenter }) => {
  const appearState =
    state === StateConfig.END || state === StateConfig.CENTER
      ? "left-disapear"
      : "right-disapear";

  return (
    <DynamicFormRoot>
      <CSSTransition
        in={state === StateConfig.START}
        timeout={1000}
        unmountOnExit
        classNames={appearState}
      >
        <LoginForm props={loginForm} slideToTheCenter={slideToTheCenter} />
      </CSSTransition>

      <CSSTransition
        in={state === StateConfig.CENTER}
        timeout={1000}
        unmountOnExit
        classNames={appearState}
      >
        <ResetForm props={resetForm} />
      </CSSTransition>

      <CSSTransition
        in={state === StateConfig.END}
        timeout={1000}
        unmountOnExit
        classNames={appearState}
      >
        <CreateForm props={createForm} />
      </CSSTransition>
    </DynamicFormRoot>
  );
};

export default DynamicForm;

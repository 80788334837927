import { Typography, Button } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import { FieldArea } from "../DynamicForm.style";
import Form from "../../../../../store/form-store";
import { FC } from "react";
import { observer } from "mobx-react-lite";

type Props = {
  props: Form;
};

const ResetForm: FC<Props> = observer(({ props }) => {
  const {
    form: { email },
    onChangeFormHandler,
  } = props;

  return (
    <FieldArea>
      <Typography fontWeight={600} fontSize={32} align="center">
        Reset password
      </Typography>
      <Typography fontSize={"1.4rem"}>
        Lost your password? Please enter your email address. You will receive a
        link to create a new password via email.
      </Typography>
      <CustomTextField
        label={"Email"}
        value={email}
        onChange={(e) => onChangeFormHandler("email", e.target.value)}
        placeholder="Enter your email"
      />
      <Button fullWidth variant="contained">
        Login
      </Button>
    </FieldArea>
  );
});

export default ResetForm;

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { palette } from "../../../../components/theme/palette";

export const BackgroundRoot = styled(Box)`
  height: 100vh;
  position: relative;
  background: linear-gradient(
    270deg,
    ${palette.primary.light} 17%,
    ${palette.primary.main} 66%
  );
`;

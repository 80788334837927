const LinkedIn = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_68_435)">
        <path
          d="M0 5.6C0 2.5072 2.50721 0 5.6 0H34.4C37.4928 0 40 2.50721 40 5.6V34.4C40 37.4928 37.4928 40 34.4 40H5.6C2.5072 40 0 37.4928 0 34.4V5.6Z"
          fill="white"
        />
        <path
          d="M14.4 10.8C14.4 12.7882 12.7882 14.4 10.8 14.4C8.81177 14.4 7.2 12.7882 7.2 10.8C7.2 8.81177 8.81177 7.2 10.8 7.2C12.7882 7.2 14.4 8.81177 14.4 10.8Z"
          fill="#0077B5"
        />
        <path
          d="M8 16.8C8 16.3582 8.35817 16 8.8 16H12.8C13.2418 16 13.6 16.3582 13.6 16.8V32C13.6 32.4418 13.2418 32.8 12.8 32.8H8.8C8.35817 32.8 8 32.4418 8 32V16.8Z"
          fill="#0077B5"
        />
        <path
          d="M16.8 16L20.4 16C20.8418 16 21.2 16.3582 21.2 16.8V17.6C22.8 15.6 25.7333 15.4667 27.2 15.6C31.7723 16.0156 32.5333 20.6667 32.4 23.2L32.4 32C32.4 32.4418 32.0418 32.8 31.6 32.8L28 32.8C27.5582 32.8 27.2 32.4418 27.2 32V23.2C27.0667 22.1333 26.32 20 24.4 20C22.48 20 21.3333 22.4 21.2 23.2V32C21.2 32.4418 20.8418 32.8 20.4 32.8L16.8 32.8C16.3582 32.8 16 32.4418 16 32V16.8C16 16.3582 16.3582 16 16.8 16Z"
          fill="#0077B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_435">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedIn;

import React from "react";
import {
  AccountManagerPanel,
  AlternativeLogin,
  BackIconWrapper,
  CreateAccount,
  FormRoot,
  LoginButtonsSection,
  PoliciesSection,
  SignIn,
  StateConfig,
  StyledLink,
} from "./FormComponent.style";
import Logo from "../../../../components/icons/Logo";
import { IconButton, Typography } from "@mui/material";
import Google from "../../../../components/icons/Google";
import LinkedIn from "../../../../components/icons/LinkedIn";
import { useState, useRef } from "react";
import DynamicForm from "../DynamicForm/DynamicForm";
import BackIcon from "../../../../components/icons/BackIcon";
import { signUpWithGoogleHandler } from "../../../../services";

const FormComponent = () => {
  const [formState, setFormState] = useState<StateConfig>(StateConfig.START);
  const [dirty, setDirty] = useState<boolean>(false);
  const prevState = useRef<StateConfig>(StateConfig.START);

  const linkArr: { id: number; title: string }[] = [
    { id: 1, title: "Privary policy" },
    { id: 2, title: "Suport" },
    { id: 3, title: "Terms of use" },
  ];

  const wayOfFormConfig = {
    CENTER: {
      START: StateConfig.END,
      CENTER: StateConfig.END,
    },
    END: {
      START: StateConfig.END,
      END: StateConfig.START,
    },
  };

  const onNextStep = (arg: StateConfig) => {
    if (!dirty) setDirty(true);
    setFormState(arg);
  };

  const onPreviousStep = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!dirty) setDirty(true);
    const keysOfConfig = Object.keys(
      wayOfFormConfig[formState as keyof typeof wayOfFormConfig]
    );

    const keyOfPreviousState =
      keysOfConfig.findIndex((item) => item === formState) - 1;

    prevState.current = formState;

    setFormState(keysOfConfig[keyOfPreviousState] as StateConfig);
  };

  const slideToTheEnd = () => onNextStep(StateConfig.END);
  const slideToTheCenter = () => onNextStep(StateConfig.CENTER);

  return (
    <FormRoot state={formState} prevState={prevState.current} dirty={dirty}>
      {formState !== StateConfig.START && (
        <BackIconWrapper onClick={(e) => onPreviousStep(e)}>
          <BackIcon />
        </BackIconWrapper>
      )}

      <Logo />
      <DynamicForm state={formState} slideToTheCenter={slideToTheCenter} />
      {formState === StateConfig.START && (
        <AccountManagerPanel>
          <AlternativeLogin>
            <Typography fontSize={"1.4rem"} align="center">
              Or login with
            </Typography>
          </AlternativeLogin>

          <LoginButtonsSection>
            <IconButton onClick={signUpWithGoogleHandler}>
              <Google />
            </IconButton>
            <IconButton>
              <LinkedIn />
            </IconButton>
          </LoginButtonsSection>

          <CreateAccount>
            <Typography fontSize={"1.4rem"}>Dont have an account</Typography>
            <SignIn onClick={slideToTheEnd}>Sign up</SignIn>
          </CreateAccount>

          <PoliciesSection>
            {linkArr.map((item) => (
              <StyledLink key={item.id}>{item.title}</StyledLink>
            ))}
          </PoliciesSection>
        </AccountManagerPanel>
      )}
    </FormRoot>
  );
};

export default FormComponent;

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { palette } from "../../../../components/theme/palette";
import theme from "../../../../components/theme";

export const FieldArea = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const DynamicFormRoot = styled(Box)`
  width: 70%;
  max-width: 400px;
  overflow-x: hidden;
  position: relative;
  height: 520px;
  margin-top: 5rem;

  ${theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

export const ForgotPassword = styled(Typography)`
  color: ${palette.primary.main};
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const BackIcon = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2055 28.0088L17.7621 32.5117C18.0448 32.7912 18.0448 33.2442 17.7621 33.5237C17.4793 33.8031 17.0208 33.8031 16.7381 33.5237L10.9455 27.7992C10.6627 27.5198 10.6627 27.0667 10.9455 26.7873L16.7381 21.0628C17.0208 20.7834 17.4793 20.7834 17.7621 21.0628C18.0448 21.3423 18.0448 21.7954 17.7621 22.0748L13.2055 26.5777H33.1797C34.3794 26.5777 35.3519 25.6166 35.3519 24.431V15.8444C35.3519 14.6588 34.3794 13.6977 33.1797 13.6977H11.4575C11.0576 13.6977 10.7334 13.3773 10.7334 12.9822C10.7334 12.587 11.0576 12.2666 11.4575 12.2666H33.1797C35.1792 12.2666 36.8001 13.8684 36.8001 15.8444V24.431C36.8001 26.407 35.1792 28.0088 33.1797 28.0088H13.2055Z"
        fill="#7A829A"
      />
    </svg>
  );
};

export default BackIcon;

import { useState } from "react";
import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { Root } from "./CustomTextField.style";
import { FC } from "react";
import { palette } from "../theme/palette";
import PasswordVisabilityOff from "../icons/PasswordVisabilityOff";
import PasswordVisabilityOn from "../icons/PasswordVisabilityOn";

const CustomTextField: FC<TextFieldProps> = ({
  label,
  placeholder,
  type,
  value,
  onChange,
}) => {
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const changePasswordState = () => {
    setPasswordShow((prev) => !prev);
  };

  const endAdornment =
    type === "password" ? (
      <Box onClick={changePasswordState}>
        {passwordShow ? <PasswordVisabilityOn /> : <PasswordVisabilityOff />}
      </Box>
    ) : undefined;

  return (
    <Root>
      <Typography pb={1} fontSize={"1.4rem"}>
        {label}
      </Typography>
      <TextField
        onChange={onChange}
        value={value}
        InputProps={{
          endAdornment,
        }}
        type={passwordShow ? "text" : type}
        sx={{
          input: {
            color: palette.textField.color,
            fontSize: "1.4rem",
          },
          placeholder: {
            fontSize: "1.4rem",
            color: palette.textField.placeholder,
          },
        }}
        fullWidth
        placeholder={placeholder}
      />
    </Root>
  );
};

export default CustomTextField;

const Logo = () => {
  return (
    <svg
      width="220"
      height="158"
      viewBox="0 0 220 158"
      style={{ marginTop: "100px" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.251 22.1791H68.673C78.2737 22.1791 86.0813 29.9867 86.0813 39.5874V69.9184C86.0813 77.9639 79.5358 84.5123 71.4874 84.5123H48.6303C42.7782 84.5123 37.0277 86.5113 32.4377 90.141L24.2878 96.582C23.4203 97.2667 22.3816 97.6178 21.3284 97.6178C20.6175 97.6178 19.9038 97.4582 19.2336 97.1333C17.5682 96.3267 16.5324 94.6758 16.5324 92.8276V84.2251C9.87083 82.8759 4.83984 76.9745 4.83984 69.9213V39.5874C4.84275 29.9867 12.6503 22.1791 22.251 22.1791Z"
        fill="#30C3DD"
      />
      <path
        d="M52.476 36.2662H63.6124V57.7998C63.6124 59.9351 63.2757 61.9555 62.6023 63.8608C61.9452 65.7497 60.9022 67.4087 59.4732 68.8377C58.0607 70.2503 56.5742 71.244 55.0137 71.8189C52.8456 72.6237 50.2422 73.0261 47.2035 73.0261C45.446 73.0261 43.5242 72.903 41.4382 72.6566C39.3686 72.4102 37.6357 71.9256 36.2396 71.2029C34.8434 70.4638 33.5622 69.4208 32.396 68.0739C31.2462 66.727 30.4578 65.3391 30.0308 63.9101C29.3409 61.6105 28.996 59.5738 28.996 57.7998V36.2662H40.1324V58.3172C40.1324 60.2883 40.6744 61.8323 41.7585 62.9492C42.859 64.0497 44.3783 64.5999 46.3165 64.5999C48.2383 64.5999 49.7412 64.0579 50.8253 62.9738C51.9258 61.8733 52.476 60.3211 52.476 58.3172V36.2662Z"
        fill="white"
      />
      <path
        d="M89.8628 22.1791C89.8628 29.146 84.215 34.7938 77.2481 34.7938C70.2812 34.7938 64.6334 29.146 64.6334 22.1791C64.6334 15.2122 70.2812 9.56445 77.2481 9.56445C84.215 9.56445 89.8628 15.2122 89.8628 22.1791Z"
        fill="#FF3232"
      />
      <path
        d="M78.9136 18.1562V26.9727H76.4774V21.1956C76.0832 21.4952 75.7008 21.7376 75.3303 21.9229C74.9637 22.1082 74.5024 22.2856 73.9466 22.4551V20.4801C74.7666 20.216 75.4032 19.8986 75.8565 19.5281C76.3099 19.1575 76.6647 18.7002 76.9209 18.1562H78.9136Z"
        fill="white"
      />
      <path
        d="M91.1768 93.6923V57.5729H100.564V61.4411C101.861 59.815 103.052 58.7145 104.136 58.1396C105.598 57.3676 107.216 56.9816 108.99 56.9816C112.489 56.9816 115.191 58.3203 117.096 60.9976C119.018 63.6749 119.979 66.9846 119.979 70.9267C119.979 75.2795 118.936 78.6056 116.85 80.9051C114.764 83.1883 112.127 84.3298 108.941 84.3298C107.397 84.3298 105.984 84.067 104.703 83.5414C103.438 83.0158 102.305 82.2356 101.303 81.2008V93.6923H91.1768ZM101.229 70.7296C101.229 72.7992 101.664 74.335 102.535 75.3369C103.405 76.3389 104.506 76.8399 105.836 76.8399C107.003 76.8399 107.98 76.3635 108.768 75.4109C109.557 74.4418 109.951 72.8074 109.951 70.5079C109.951 68.389 109.54 66.8368 108.719 65.8513C107.898 64.8493 106.896 64.3484 105.713 64.3484C104.432 64.3484 103.364 64.8493 102.51 65.8513C101.656 66.8532 101.229 68.4794 101.229 70.7296Z"
        fill="#30C3DD"
      />
      <path
        d="M123.551 70.7296C123.551 66.7383 124.898 63.4532 127.592 60.8744C130.286 58.2792 133.924 56.9816 138.506 56.9816C143.746 56.9816 147.705 58.5009 150.382 61.5396C152.534 63.987 153.61 67.0011 153.61 70.5818C153.61 74.606 152.271 77.9075 149.594 80.4863C146.933 83.0487 143.245 84.3298 138.531 84.3298C134.326 84.3298 130.926 83.2622 128.331 81.1269C125.144 78.4824 123.551 75.0166 123.551 70.7296ZM133.603 70.705C133.603 73.0374 134.072 74.7621 135.008 75.879C135.961 76.9959 137.151 77.5544 138.58 77.5544C140.026 77.5544 141.208 77.0041 142.128 75.9036C143.064 74.8031 143.533 73.0374 143.533 70.6064C143.533 68.3397 143.064 66.6561 142.128 65.5556C141.192 64.4387 140.034 63.8803 138.654 63.8803C137.192 63.8803 135.985 64.4469 135.032 65.5803C134.08 66.6972 133.603 68.4054 133.603 70.705Z"
        fill="#30C3DD"
      />
      <path
        d="M156.665 76.4949L166.618 75.5587C167.029 76.7413 167.604 77.5872 168.343 78.0964C169.082 78.6056 170.068 78.8602 171.3 78.8602C172.647 78.8602 173.69 78.5727 174.429 77.9979C175.004 77.5708 175.291 77.037 175.291 76.3964C175.291 75.6737 174.913 75.1152 174.158 74.721C173.616 74.4418 172.178 74.0968 169.846 73.6862C166.364 73.0785 163.941 72.52 162.578 72.0108C161.231 71.4852 160.089 70.6064 159.153 69.3745C158.233 68.1426 157.773 66.7383 157.773 65.1614C157.773 63.4368 158.274 61.9503 159.276 60.7019C160.278 59.4536 161.658 58.5256 163.416 57.9178C165.173 57.2937 167.53 56.9816 170.487 56.9816C173.607 56.9816 175.907 57.2198 177.385 57.6961C178.88 58.1724 180.12 58.9116 181.106 59.9135C182.108 60.9155 182.937 62.2706 183.594 63.9788L174.084 64.915C173.837 64.0774 173.427 63.4614 172.852 63.0672C172.063 62.5416 171.111 62.2788 169.994 62.2788C168.861 62.2788 168.031 62.4841 167.505 62.8947C166.996 63.2889 166.742 63.7735 166.742 64.3484C166.742 64.989 167.07 65.4735 167.727 65.802C168.384 66.1305 169.813 66.4262 172.014 66.689C175.349 67.0668 177.829 67.5924 179.455 68.2658C181.081 68.9393 182.321 69.9001 183.175 71.1485C184.046 72.3968 184.481 73.7683 184.481 75.263C184.481 76.7742 184.021 78.2442 183.101 79.6732C182.198 81.1022 180.761 82.2438 178.79 83.0979C176.835 83.9356 174.166 84.3545 170.782 84.3545C166.003 84.3545 162.594 83.6728 160.557 82.3095C158.537 80.9462 157.24 79.008 156.665 76.4949Z"
        fill="#30C3DD"
      />
      <path
        d="M201.383 47.6191V57.5729H206.902V64.8904H201.383V74.179C201.383 75.2959 201.49 76.035 201.703 76.3964C202.032 76.9548 202.606 77.2341 203.428 77.2341C204.167 77.2341 205.202 77.0205 206.532 76.5935L207.271 83.5168C204.791 84.0588 202.475 84.3298 200.323 84.3298C197.827 84.3298 195.987 84.0095 194.804 83.3689C193.622 82.7284 192.743 81.7593 192.168 80.4617C191.61 79.1476 191.33 77.0288 191.33 74.105V64.8904H187.635V57.5729H191.33V52.7685L201.383 47.6191Z"
        fill="#30C3DD"
      />
      <path
        d="M53.6523 119.128V125.973H51.9213V119.128L47.1479 111.548H49.1937L52.8917 117.475L56.616 111.548H58.5831L53.6523 119.128Z"
        fill="#30C3DD"
      />
      <path
        d="M64.6442 126.209C63.123 126.209 61.9341 125.737 61.0773 124.793C60.238 123.848 59.8184 122.581 59.8184 120.99C59.8184 119.381 60.2468 118.113 61.1035 117.187C61.9778 116.243 63.1842 115.77 64.7229 115.77C66.2616 115.77 67.4505 116.234 68.2898 117.16C69.1291 118.087 69.5487 119.355 69.5487 120.963C69.5487 122.537 69.1116 123.805 68.2374 124.766C67.3631 125.728 66.1654 126.209 64.6442 126.209ZM64.7229 117.108C63.7263 117.108 62.9482 117.475 62.3887 118.21C61.8466 118.944 61.5756 119.871 61.5756 120.99C61.5756 122.109 61.8466 123.027 62.3887 123.744C62.9482 124.46 63.7263 124.819 64.7229 124.819C65.7021 124.819 66.4626 124.46 67.0047 123.744C67.5467 123.009 67.8177 122.091 67.8177 120.99C67.8177 119.871 67.538 118.944 66.9785 118.21C66.4364 117.475 65.6846 117.108 64.7229 117.108Z"
        fill="#30C3DD"
      />
      <path
        d="M73.7258 122.38C73.7258 123.236 73.8919 123.848 74.2242 124.216C74.5564 124.583 75.1246 124.766 75.9289 124.766C76.7507 124.766 77.6687 124.53 78.6828 124.058V115.902H80.3876V125.973H79.2073L78.8402 125.239C77.5638 125.903 76.3835 126.235 75.2995 126.235C74.2504 126.235 73.4373 125.929 72.8603 125.317C72.3008 124.705 72.0211 123.866 72.0211 122.799V115.902H73.7258V122.38Z"
        fill="#30C3DD"
      />
      <path
        d="M87.6443 115.77C88.029 115.77 88.335 115.797 88.5623 115.849C88.8071 115.884 89.0431 115.954 89.2704 116.059L88.6672 117.58C88.2126 117.37 87.7143 117.265 87.1722 117.265C86.4379 117.265 85.7123 117.51 84.9954 118V125.973H83.2906V115.902H84.5233L84.8905 116.767C85.7647 116.103 86.6827 115.77 87.6443 115.77Z"
        fill="#30C3DD"
      />
      <path
        d="M98.442 122.38C98.442 123.236 98.6081 123.848 98.9403 124.216C99.2725 124.583 99.8408 124.766 100.645 124.766C101.467 124.766 102.385 124.53 103.399 124.058V115.902H105.104V125.973H103.923L103.556 125.239C102.28 125.903 101.1 126.235 100.016 126.235C98.9665 126.235 98.1535 125.929 97.5765 125.317C97.017 124.705 96.7372 123.866 96.7372 122.799V115.902H98.442V122.38Z"
        fill="#30C3DD"
      />
      <path
        d="M114.668 119.573C114.668 118.717 114.502 118.105 114.17 117.737C113.855 117.37 113.296 117.187 112.492 117.187C111.635 117.187 110.708 117.423 109.712 117.895V125.973H108.007V115.902H109.187L109.554 116.741C110.761 116.076 111.941 115.744 113.095 115.744C114.161 115.744 114.974 116.05 115.534 116.662C116.094 117.257 116.373 118.087 116.373 119.154V125.973H114.668V119.573Z"
        fill="#30C3DD"
      />
      <path
        d="M120.117 113.961C119.803 113.961 119.54 113.856 119.331 113.646C119.121 113.419 119.016 113.156 119.016 112.859C119.016 112.579 119.121 112.335 119.331 112.125C119.54 111.898 119.803 111.784 120.117 111.784C120.415 111.784 120.668 111.898 120.878 112.125C121.088 112.335 121.193 112.579 121.193 112.859C121.193 113.156 121.088 113.419 120.878 113.646C120.686 113.856 120.432 113.961 120.117 113.961ZM120.957 115.902V125.973H119.252V115.902H120.957Z"
        fill="#30C3DD"
      />
      <path
        d="M128.37 126.235C127.059 126.235 125.905 125.781 124.908 124.871C123.947 123.875 123.466 122.528 123.466 120.832C123.466 118.787 124.174 117.309 125.59 116.4C126.307 115.98 127.164 115.77 128.16 115.77C129.157 115.77 130.092 116.068 130.967 116.662L131.255 115.928H132.54V131.428H130.757V125.737C130.023 126.069 129.227 126.235 128.37 126.235ZM130.757 117.79C130.407 117.563 130.066 117.405 129.734 117.318C129.402 117.23 128.991 117.187 128.501 117.187C127.435 117.187 126.622 117.536 126.062 118.236C125.503 118.918 125.223 119.844 125.223 121.016C125.223 122.152 125.511 123.079 126.088 123.796C126.665 124.513 127.478 124.871 128.528 124.871C129.367 124.871 130.11 124.714 130.757 124.399V117.79Z"
        fill="#30C3DD"
      />
      <path
        d="M137.194 122.38C137.194 123.236 137.36 123.848 137.692 124.216C138.024 124.583 138.593 124.766 139.397 124.766C140.219 124.766 141.137 124.53 142.151 124.058V115.902H143.856V125.973H142.675L142.308 125.239C141.032 125.903 139.852 126.235 138.767 126.235C137.718 126.235 136.905 125.929 136.328 125.317C135.769 124.705 135.489 123.866 135.489 122.799V115.902H137.194V122.38Z"
        fill="#30C3DD"
      />
      <path
        d="M150.798 115.77C152.126 115.77 153.149 116.173 153.866 116.977C154.583 117.764 154.942 118.883 154.942 120.334V121.252L154.837 121.462H147.939C147.939 122.319 148.262 123.114 148.909 123.848C149.521 124.46 150.474 124.766 151.768 124.766C152.625 124.766 153.438 124.635 154.207 124.373L154.522 125.632C153.455 125.999 152.38 126.183 151.296 126.183C149.67 126.183 148.428 125.746 147.572 124.871C146.715 123.997 146.287 122.747 146.287 121.121C146.287 119.582 146.662 118.314 147.414 117.318C148.219 116.286 149.346 115.77 150.798 115.77ZM153.211 120.098C153.211 119.224 153.018 118.507 152.634 117.947C152.266 117.388 151.716 117.108 150.981 117.108C150.055 117.108 149.346 117.388 148.857 117.947C148.367 118.507 148.07 119.224 147.965 120.098H153.211Z"
        fill="#30C3DD"
      />
      <path
        d="M163.178 131.428V115.902H164.384L164.725 116.662C165.582 116.068 166.526 115.77 167.557 115.77C168.537 115.77 169.367 115.98 170.049 116.4C171.5 117.309 172.226 118.787 172.226 120.832C172.226 122.581 171.736 123.927 170.757 124.871C169.796 125.781 168.65 126.235 167.321 126.235C166.465 126.235 165.652 126.069 164.882 125.737V131.428H163.178ZM167.138 117.187C166.648 117.187 166.237 117.23 165.905 117.318C165.59 117.405 165.249 117.563 164.882 117.79V124.399C165.564 124.714 166.307 124.871 167.112 124.871C168.161 124.871 168.974 124.513 169.551 123.796C170.128 123.079 170.416 122.152 170.416 121.016C170.416 119.844 170.136 118.918 169.577 118.236C169.017 117.536 168.204 117.187 167.138 117.187Z"
        fill="#30C3DD"
      />
      <path
        d="M179.03 126.209C177.509 126.209 176.32 125.737 175.463 124.793C174.624 123.848 174.204 122.581 174.204 120.99C174.204 119.381 174.633 118.113 175.489 117.187C176.364 116.243 177.57 115.77 179.109 115.77C180.647 115.77 181.836 116.234 182.676 117.16C183.515 118.087 183.935 119.355 183.935 120.963C183.935 122.537 183.497 123.805 182.623 124.766C181.749 125.728 180.551 126.209 179.03 126.209ZM179.109 117.108C178.112 117.108 177.334 117.475 176.775 118.21C176.232 118.944 175.961 119.871 175.961 120.99C175.961 122.109 176.232 123.027 176.775 123.744C177.334 124.46 178.112 124.819 179.109 124.819C180.088 124.819 180.848 124.46 181.391 123.744C181.933 123.009 182.204 122.091 182.204 120.99C182.204 119.871 181.924 118.944 181.364 118.21C180.822 117.475 180.07 117.108 179.109 117.108Z"
        fill="#30C3DD"
      />
      <path
        d="M189.58 124.766C190.192 124.766 190.708 124.653 191.128 124.425C191.565 124.181 191.783 123.831 191.783 123.376C191.783 123.009 191.626 122.721 191.311 122.511C190.997 122.301 190.332 121.986 189.318 121.567L188.321 121.173C187.884 120.963 187.526 120.78 187.246 120.622C186.966 120.448 186.713 120.194 186.486 119.862C186.258 119.512 186.145 119.075 186.145 118.551C186.145 117.606 186.529 116.907 187.299 116.452C188.085 115.998 189.047 115.77 190.184 115.77C191.145 115.77 192.107 115.989 193.069 116.426L192.649 117.685C191.862 117.353 191.102 117.187 190.367 117.187C189.615 117.187 189.003 117.3 188.531 117.528C188.077 117.737 187.849 118.043 187.849 118.446C187.849 118.848 188.007 119.163 188.321 119.39C188.654 119.617 189.213 119.879 190 120.177L190.866 120.518C191.827 120.92 192.5 121.339 192.885 121.776C193.27 122.214 193.462 122.791 193.462 123.508C193.462 124.242 193.121 124.88 192.439 125.422C191.757 125.947 190.848 126.209 189.712 126.209C188.418 126.209 187.141 125.955 185.882 125.448L186.354 124.163C187.491 124.565 188.566 124.766 189.58 124.766Z"
        fill="#30C3DD"
      />
      <path
        d="M199.576 126.183C198.562 126.183 197.836 125.912 197.399 125.37C196.98 124.81 196.77 123.971 196.77 122.852V117.318H195.065V115.902H196.77V113.436L198.475 113.305V115.902H201.779V117.318H198.475V122.747C198.475 123.446 198.579 123.953 198.789 124.268C199.017 124.583 199.445 124.74 200.074 124.74C200.669 124.74 201.202 124.688 201.674 124.583L201.963 125.737C201.176 126.034 200.38 126.183 199.576 126.183Z"
        fill="#30C3DD"
      />
    </svg>
  );
};

export default Logo;

import { PaletteMode } from "@mui/material";
import "@mui/material/styles";

export const palette = {
  mode: "dark" as PaletteMode | undefined,
  primary: {
    light: "#b3eaf4",
    main: "#30C3DD",
  },
  textField: {
    background: " #F3F5FC",
    color: "#1B2855",
    placeholder: "#7A829A",
  },
  text: {
    primary: "#7A829A",
  },
};

declare module "@mui/material/styles" {
  interface Palette {}
  interface PaletteOptions {}
}

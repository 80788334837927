import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { palette } from "../../../../components/theme/palette";
import theme from "../../../../components/theme";

export enum StateConfig {
  START = "START",
  CENTER = "CENTER",
  END = "END",
}

export const FormRoot = styled(Box)<{
  state: StateConfig;
  dirty: boolean;
  prevState?: StateConfig;
}>`
  height: 100%;
  background: white;
  width: 630px;
  border-radius: 0px 50px 50px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 0px 250px 0px rgba(16, 18, 23, 0.73);
  user-select: none;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;

  ${theme.breakpoints.down("sm")} {
    width: 100vw;
    border-radius: 0;
  }

  ${theme.breakpoints.up("sm")} {
    ${({ state }) =>
      state === StateConfig.END && "animation-name: fromBeginToEnd;"}

    ${({ state }) =>
      state === StateConfig.CENTER && "animation-name: fromBeginToCenter;"}

  ${({ state, prevState }) =>
      state === StateConfig.START &&
      prevState === StateConfig.END &&
      "animation-name: fromEndToBegin;"}

    ${({ state, prevState }) =>
      state === StateConfig.START &&
      prevState === StateConfig.CENTER &&
      "animation-name: fromCenterToBegin;"}
  }

  @keyframes fromCenterToBegin {
    0% {
      transform: translateX(calc(50vw - 50%));
    }
    20% {
      width: 800px;
    }
    100% {
      width: 630px;
      border-radius: 50px;
      transform: translateX(0);
    }
  }

  @keyframes fromBeginToCenter {
    0% {
      transform: translateX(0);
    }
    20% {
      width: 800px;
    }
    100% {
      width: 630px;
      border-radius: 50px;
      transform: translateX(calc(50vw - 50%));
    }
  }

  @keyframes fromBeginToEnd {
    0% {
      transform: translateX(0%);
    }
    20% {
      width: 800px;
    }
    100% {
      width: 630px;
      border-radius: 50px;
      transform: translateX(calc(100vw - 100%));
    }
  }

  @keyframes fromEndToBegin {
    0% {
      transform: translateX(calc(100vw - 100%));
    }
    20% {
      width: 800px;
    }

    100% {
      width: 630px;
      border-radius: 50px;
      transform: translateX(0%);
    }
  }
`;

export const AccountManagerPanel = styled(Box)`
  width: 70%;
  max-width: 400px;
  position: relative;
  flex: 1;
`;

export const SignIn = styled(Typography)`
  color: ${palette.primary.main};
  cursor: pointer;
  font-size: 1.4rem;

  border-bottom: 1px solid ${palette.primary.main};
`;

export const PoliciesSection = styled(Box)`
  display: flex;
  justify-content: center;
  position: absolute;

  width: 100%;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%);

  & > *:not(:last-child) {
    margin-right: 30px;
  }
`;

export const StyledLink = styled(Typography)`
  color: ${palette.text.primary};
  border-bottom: 1px solid ${palette.text.primary};
  padding-bottom: 3px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 1.4rem;

  &:hover {
    color: ${palette.primary.main};
    border-bottom: 1px solid ${palette.primary.main};
  }
`;

export const AlternativeLogin = styled(Box)`
  position: relative;
  color: ${palette.text.primary};
  margin-bottom: 30px;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: #cdd1e0;
    width: 38%;
    height: 1px;
    top: 50%;
  }

  &::after {
    right: 0;
    transform: translate(0, -50%);
  }
  &::before {
    left: 0;
    transform: translate(0, -50%);
  }
`;

export const BackIconWrapper = styled(Box)`
  position: absolute;
  left: 32px;
  top: 38px;
`;

export const LoginButtonsSection = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  & > *:not(:last-child) {
    margin-right: 30px;
  }
`;

export const CreateAccount = styled(Box)`
  display: flex;
  justify-content: center;
  color: ${palette.text.primary};
  margin-bottom: 80px;

  & > * {
    font-size: 1.4rem;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

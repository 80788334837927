import { FC } from "react";
import { FieldArea, ForgotPassword } from "../DynamicForm.style";
import { Button, Typography } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import { observer } from "mobx-react-lite";
import Form from "../../../../../store/form-store";

type Props = {
  slideToTheCenter: () => void;
  props: Form;
};

const LoginForm: FC<Props> = observer(({ slideToTheCenter, props }) => {
  const {
    form: { email, password },
    onChangeFormHandler,
  } = props;

  return (
    <FieldArea>
      <Typography fontWeight={600} fontSize={"3.2rem"} align="center">
        Welcome back
      </Typography>
      <CustomTextField
        value={email}
        onChange={(e) => onChangeFormHandler("email", e.target.value)}
        label={"Email"}
        type="email"
        placeholder="Enter your email"
      />
      <CustomTextField
        value={password}
        onChange={(e) => onChangeFormHandler("password", e.target.value)}
        label={"Password"}
        type="password"
        placeholder="Enter your password"
      />
      <ForgotPassword onClick={slideToTheCenter}>
        Forgot your password
      </ForgotPassword>
      <Button fullWidth variant="contained">
        Login
      </Button>
    </FieldArea>
  );
});

export default LoginForm;

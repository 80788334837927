import { Components } from "@mui/material/styles/components";
import { palette } from "../palette";

export const MuiFormControlLabel: Components["MuiFormControlLabel"] = {
  styleOverrides: {
    label: {
      fontSize: "1.4rem",
      color: palette.text.primary,
    },
  },
};
